import axios, { AxiosResponse, CancelToken } from "axios";
import firebase from "gatsby-plugin-firebase";
import { API_URL } from "../globals/variables";
import { CompareResult, SelectionId, ShorthandResponse, Tag, TagId, TClass, Timetable, UserInfo } from "./types";

export async function getTimetable(
  selectionIds: SelectionId[],
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Timetable>> {
  return axios.get(`${API_URL}/timetable?selectionIds=${selectionIds.join("|")}`, {
    headers: {
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getRawTimetable(
  tagId: TagId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Timetable>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.get(`${API_URL}/timetable/raw?tagId=${tagId}`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getChildrenTags(
  tagUuid: TagId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Tag[]>> {
  return axios.get(`${API_URL}/tags?tagId=${tagUuid}`, {
    headers: {
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getOwnedTags(lang = "en", cancelToken?: CancelToken): Promise<AxiosResponse<Tag[]>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.get(`${API_URL}/tags/owned`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getShorthands(
  selectionId: SelectionId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<ShorthandResponse>> {
  return axios.get(`${API_URL}/shorthands?selectionId=${selectionId}`, {
    headers: {
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getClasses(
  tagId: TagId,
  search = "",
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Array<TClass>>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.get(`${API_URL}/classes?tagId=${tagId}&search=${search}`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getUserInfo(lang = "en", cancelToken?: CancelToken): Promise<AxiosResponse<UserInfo>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.get(`${API_URL}/user/info`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

/**
 * Create a Tag
 * @param name The name of the new Tag
 * @param shorthand The shorthand of the new Tag
 * @param parent Tag UUID under which the new Tag will be placed
 * @param lang The Language of the response
 * @param cancelToken Axios cancel token
 */
export async function createTag(
  name: string,
  shorthand: string,
  parent: TagId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Tag>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.post(
    `${API_URL}/tag?tagId=${parent}`,
    {
      shorthand,
      name,
    },
    {
      headers: {
        Authorization: token,
        "Accept-Language": lang,
      },
      cancelToken,
    }
  );
}

export async function editTag(
  name: string,
  shorthand: string,
  tagId: TagId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Tag>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.put(
    `${API_URL}/tag?tagId=${tagId}`,
    {
      shorthand,
      name,
    },
    {
      headers: {
        Authorization: token,
        "Accept-Language": lang,
      },
      cancelToken,
    }
  );
}

export async function getTag(tagId: TagId, lang = "en", cancelToken?: CancelToken): Promise<AxiosResponse<Tag>> {
  return axios.get(`${API_URL}/tag?tagId=${tagId}`, {
    headers: {
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function deleteTag(tagId: TagId, lang = "en", cancelToken?: CancelToken): Promise<AxiosResponse<void>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.delete(`${API_URL}/tag?tagId=${tagId}`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function compareTimetable(
  first: TagId | Timetable,
  second: TagId | Timetable,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<CompareResult>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.post(
    `${API_URL}/timetable/compare`,
    {
      first,
      second,
    },
    {
      headers: {
        Authorization: token,
        "Accept-Language": lang,
      },
      cancelToken,
    }
  );
}

export async function deleteTimetable(
  tagId: TagId,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<void>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.delete(`${API_URL}/timetable?tagId=${tagId}`, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function getTimetablePreview(
  tagId: TagId,
  timetable: any,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<Timetable>> {
  const token = await firebase.auth().currentUser.getIdToken();
  const data = new FormData();
  data.append("table", new Blob([JSON.stringify(timetable)], { type: "application/json" }), "timetable.json");
  return axios.post(`${API_URL}/timetable/preview?tagId=${tagId}`, data, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}

export async function uploadTimetable(
  tagId: TagId,
  data: Timetable,
  lang = "en",
  cancelToken?: CancelToken
): Promise<AxiosResponse<void>> {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.post(`${API_URL}/timetable?tagId=${tagId}`, data, {
    headers: {
      Authorization: token,
      "Accept-Language": lang,
    },
    cancelToken,
  });
}
